body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #00ffff;
  text-align: center;
  color: white;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/*HTML TAGS */
h2{
  font-size: 60px;
  letter-spacing: 20px;
  padding: 0;
}

li{
  font-size: 60px;
  letter-spacing: 20px;
  list-style-type: none;
}

a{
  text-decoration: none;
  color: white;
}

img{
  vertical-align: middle;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 65%;
  position: relative;
}


button{
  cursor: pointer;
  text-decoration: none;
  background-color: Transparent;
  border: none;
  outline:none;
}

div{
  display: block;
}
/* -------------------------------------------------------------------------------- */

/*Class css */
.Resume{
  color: white;
  font-size: 45px;
  letter-spacing: 20px;
  font-weight: normal;
}

.Slideshow{
  position:relative;
  display:inline-block;
}

.Title{
  font-size: 60px;

  letter-spacing: 20px;
  overflow: hidden;
  list-style-type: none;
  float: left;
}

.Titlelist{
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;

  float: center;
}

.X{
  font-size: 60px;
  color: white;
  letter-spacing: 20px;
  overflow: hidden;
  list-style-type: none;
  float: left;
}

.Y{
  font-size: 60px;
  color: white;
  letter-spacing: 20px;
  overflow: hidden;
  list-style-type: none;
  float: left;
}

.Z{
  font-size: 60px;
  color: white;
  letter-spacing: 20px;
  overflow: hidden;
  list-style-type: none;
  float: left;
}

.prev, .next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
}


.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}


/*-------------------------------------------------------------------------------------- */



#left-btn{
  left: 0;
}
#right-btn{
  right:0;
}


/* Hover interactions  */
.AWS:hover{
  color: orange
}

.Resume:hover{
  color:black;
}

.Reactjs:hover{
  color:#00008B;
}

.Github:hover{
  color:#B22222;
}
.X:hover{
  color:black
}

.Y:hover{
    color:gold
}

.Z:hover{
  color:#FF0000
}

.prev:hover, .next:hover {
  background-color: rgba(0,0,0,0.8);
}
